import React, { useState, useMemo, useCallback, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import PostItem from '../components/PostItem';
import WithHeroLayout from '../layouts/WithHeroLayout';
import Input from '../components/form-elements/Input';
import SectionDescription from '../components/formatting/SectionDescription';
import { device, paginationImgs } from '../constants';
import LinkButton from '../components/form-elements/LinkButton';
import SiteConfigContext from '../context/SiteConfigContext';
import JournalsContext from '../context/JournalsContext';
import { PageProps } from 'gatsby';
import urljoin from 'url-join';

const Container = styled.div`
  padding: ${props => props.theme.paddings.pd}px;

  @media screen and ${device.laptop} {
    padding: calc(${props => props.theme.paddings.pd}px * 2.5);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap-reverse;
`;

const PostListWrapper = styled.div`
  flex: 2 1 40vw;
`;

const PostList = styled.div``;

const Sidebar = styled.div`
  flex: 1 1 350px;
  padding-top: ${props => props.theme.paddings.pd}px;
  border-top: 1px solid ${props => props.theme.colors.purple};

  @media screen and ${device.laptop} {
    margin-top: 48px;
    margin-left: calc(${props => props.theme.paddings.pd}px * 1.5);
  }
`;
const SidebarLink = styled.a`
  color: #000;
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;
const Widget = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.paddings.pd}px;
`;
const WidgetTitle = styled.h3`
  color: #000;
  font-family: 'Open Sans';
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;

const PaginationWrapper = styled.div`
  margin-top: ${props => props.theme.paddings.pd}px;

  & button {
    background-color: #80225f;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 55px;
  }
`;

const JournalIndexPage: React.FC<PageProps> = function (props) {
  const { location } = props;
  const journals = useContext(JournalsContext);

  const siteCfg = useContext(SiteConfigContext);
  const journal_sidebar_links = siteCfg?.journal_sidebar_links;

  const entries = useMemo(
    () =>
      journals?.map(journal => ({
        ...journal,
        link: urljoin('/journal', journal.link),
      })) ?? [],
    [journals]
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [pageNum, setPageNum] = useState(0);
  const maxPageNum = Math.floor(entries.length / 4);

  const paginatedResults = useMemo(() => {
    const startIndex = pageNum * 4;
    const endIndex =
      startIndex + 4 <= entries.length - 1 ? startIndex + 4 : entries.length;

    return entries.slice(startIndex, endIndex);
  }, [entries, pageNum]);

  const searchResults = useMemo(() => {
    return entries.filter(journal =>
      journal.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [entries, searchQuery]);

  const prevPage = useCallback(() => {
    if (pageNum > 0) {
      setPageNum(pageNum - 1);
    }
  }, [pageNum]);
  const nextPage = useCallback(() => {
    if (pageNum < maxPageNum) {
      setPageNum(pageNum + 1);
    }
  }, [pageNum]);

  const theme = useTheme();

  return (
    <WithHeroLayout
      pageTitle={siteCfg?.journal_page_configuration?.title ?? 'Journal'}
      bgImgSrc="journal" // @TODO: Make configurable from CMS
      color={theme.colors.purple} // @TODO: Make configurable from CMS
      path={location?.pathname}
    >
      <Container>
        {/* @TODO: Make below paragraph configurable in CMS */}
        <SectionDescription>
          {siteCfg?.journal_page_configuration?.description}
        </SectionDescription>
        <ContentContainer>
          <PostListWrapper>
            <PostList>
              {searchQuery.length == 0 &&
                paginatedResults?.map((journal, index: number) => (
                  <PostItem key={`PAGIN-${index}`} post={journal} />
                ))}

              {searchQuery.length >= 1 &&
                searchResults?.map((journal, index: number) => (
                  <PostItem key={`SEARCH-${index}`} post={journal} />
                ))}
            </PostList>
            {searchQuery.length == 0 && (
              <PaginationWrapper>
                {pageNum > 0 ? (
                  <LinkButton
                    buttonType="primary"
                    buttonText=""
                    screenreaderText={'See previous 4 articles'}
                    background={paginationImgs.leftArrow}
                    link="#"
                    noLink={true}
                    noHoverEffects={true}
                    onClick={prevPage}
                  />
                ) : null}
                {pageNum < maxPageNum ? (
                  <LinkButton
                    buttonType="primary"
                    buttonText=""
                    screenreaderText={'See next 4 articles'}
                    background={paginationImgs.rightArrow}
                    link="#"
                    noLink={true}
                    noHoverEffects={true}
                    onClick={nextPage}
                  />
                ) : null}
              </PaginationWrapper>
            )}
          </PostListWrapper>

          <Sidebar>
            <Widget>
              <WidgetTitle>Search</WidgetTitle>
              <Input
                type="search"
                onChange={(e: any) => setSearchQuery(e.target.value)}
                placeholder="Search title, keywords ..."
                value={searchQuery}
              />
            </Widget>
            <Widget>
              {(journal_sidebar_links?.length ?? 0) > 0 && (
                <>
                  <WidgetTitle>Links</WidgetTitle>

                  {journal_sidebar_links?.map((linkObj: any, index: number) => (
                    <SidebarLink key={index} href={linkObj.link}>
                      {linkObj.text}
                    </SidebarLink>
                  ))}
                </>
              )}
            </Widget>
          </Sidebar>
        </ContentContainer>
      </Container>
    </WithHeroLayout>
  );
};

export default JournalIndexPage;
