import React from 'react';
import styled from 'styled-components';
import DateAndAuthor from './formatting/DateAndAuthor';
import moment from 'moment';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: calc(${props => props.theme.paddings.pd}px * 1.5);
  padding-bottom: calc(${props => props.theme.paddings.pd}px * 1.5);
  border-bottom: 1px solid ${props => props.theme.colors.purple};
`;
const ContentWrapper = styled.div`
  display: flex;
`;

const BigTitle = styled.h1`
  & > a {
    color: #000;
  }
`;

const SmallTitle = styled.h3`
  font-size: 24px;
  & > a {
    color: #000;
  }
`;

const Excerpt = styled.p`
  font-size: 32px;
  max-width: 70%;
  margin-right: ${props => props.theme.paddings.pd}px;
`;

interface Props {
  post: App.Journal;
  smallTitle?: boolean;
}

const PostItem: React.FC<Props> = function (props) {
  const { post, smallTitle } = props;
  const { title, pub_date, link, excerpt, dc_creator } = post;

  const formattedDate = moment(pub_date).format('MMMM DD, YYYY');

  return (
    <Wrapper>
      {smallTitle ? (
        <SmallTitle>
          <a href={link}>{title}</a>
        </SmallTitle>
      ) : (
        <BigTitle>
          <a href={link}>{title}</a>
        </BigTitle>
      )}

      <ContentWrapper>
        <Excerpt>{excerpt}</Excerpt>
        <DateAndAuthor>
          {formattedDate}
          <br />
          by {dc_creator}
        </DateAndAuthor>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PostItem;
